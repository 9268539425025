import React from 'react';
import { Layout } from '@douyinfe/semi-ui';
import Log from "./pages/Log";
import {ToastContainer} from "react-toastify";

const { Content } = Layout;

function App() {
  return (
      <>
          <ToastContainer />

        <div className="App" style={{
          width: '90%', // 控制宽度为70%
          margin: '0 auto', // 自动边距实现水平居中
          maxHeight: '800px' // 确保填满视口高度
        }}>
          <Layout style={{
            overflow: 'auto',
            flexDirection: 'column',
          }}>
            <Content style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: '100%',
              padding: '10px' ,
              boxSizing: 'border-box'
            }}>
              <Log  />
            </Content>
          </Layout>
        </div>
      </>

  );
}

export default App;
